<template>
<div id="pageTable">
    <v-container grid-list-xl fluid>
        <v-card>
            <v-layout class="d-flex flex-lg-row flex-md-column flex-sm-column" wrap pa-3>
                <v-flex lg12 md12 sm12 xs12>
                    <v-layout row wrap>
                        <v-toolbar flat color="blue-grey" dark>
                            <v-toolbar-title>Tỷ giá - phí</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text>
                            <v-layout row wrap>
                                <v-flex lg6 md6 sm12>
                                    <h-currency-input v-model="configuration.Currency" :decimal="0" label="Tỷ giá" class="input-group--focused"></h-currency-input>
                                </v-flex>
                                <v-flex lg6 md6 sm12>
                                    <h-currency-input v-model="configuration.ExchangeRevenue" :decimal="0" label="Lãi tỷ giá" class="input-group--focused"></h-currency-input>
                                </v-flex>
                            </v-layout>
                            <v-layout row wrap>
                                <v-flex lg6 md6 sm12>
                                    <h-currency-input v-model="configuration.MinFeeBuy" :decimal="0" label="Phí mua tối thiểu" class="input-group--focused"></h-currency-input>
                                </v-flex>
                                <v-flex lg6 md6 sm12>
                                    <v-text-field type="number" v-model="configuration.OrderCompleteDate" label="Số ngày tự động hoàn thành đơn" class="input-group--focused"></v-text-field>
                                </v-flex>
                            </v-layout>
                            <v-layout row wrap>
                                <v-flex lg6 md6 sm12>
                                    <h-currency-input :decimal="0" v-model="configuration.Density" label="Tỷ trọng cân nặng" class="input-group--focused"></h-currency-input>
                                </v-flex>
                                <v-flex lg6 md6 sm12>
                                    <h-currency-input :decimal="0" v-model="configuration.Surcharge" label="Phụ phí" class="input-group--focused"></h-currency-input>
                                </v-flex>
                            </v-layout>
                            <v-layout row wrap>
                                <v-flex lg6 md6 sm12>
                                    <h-currency-input v-model="configuration.TransportPrice" :decimal="0" label="Giá cân nặng (gốc)" class="input-group--focused"></h-currency-input>
                                </v-flex>
                                <v-flex lg6 md6 sm12>
                                    <h-currency-input v-model="configuration.TransportPriveVolume" :decimal="0" label="Giá khối (gốc)" class="input-group--focused"></h-currency-input>
                                </v-flex>
                            </v-layout>
                            <v-layout row wrap>
                                <v-flex lg12 md12 sm12 xs12>
                                    <v-textarea rows="5" v-model="configuration.ProductTypes" label="Loại hàng" class="input-group--focused"></v-textarea>
                                </v-flex>
                            </v-layout>
                        </v-card-text>
                    </v-layout>
                     <v-layout row wrap>
                        <v-toolbar flat color="blue-grey" dark>
                            <v-toolbar-title>Thông báo khác hàng</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text>
                            <v-layout row wrap>
                                <v-flex lg12 md12 sm12 xs12>
                                    <vue-editor v-model="configuration.NoteForCustomer" label="Thông báo khách hàng" class="input-group--focused"></vue-editor>
                                </v-flex>
                            </v-layout>
                        </v-card-text>
                    </v-layout>
                </v-flex>
            </v-layout>
        </v-card>
    </v-container>
</div>
</template>

<script>
import { mapState } from "vuex";
import { sendErrorNotice } from '@/commons/utils';
import { VueEditor } from "vue2-editor";

export default {
    components: {
        VueEditor
    },
    computed: {
        ...mapState({
            loading: state => state.configuration.loading,
            configuration: state => state.configuration.selected
        })
    },
    methods: {
        refreshData() {
            this.$store.dispatch("configuration/getDetail", 1);
        },
        saveData() {
            if(this.configuration.ProductTypes != '' && !this.isJsonString(this.configuration.ProductTypes)) {
                sendErrorNotice('Loại sản phẩm không đúng cú pháp JSON.');
                return;
            }
            this.$store.dispatch("configuration/setDetail", {
                data: this.configuration,
                id: this.configuration.ID
            });
        },
        isJsonString(str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        }
    },
    mounted() {
        this.refreshData();
        this.$root.$emit('REQUEST_ROOT_OPTION', {});
        this.$root.$on("REQUEST_SAVE_DATA", this.saveData);
        if (this.$route.query.uid && this.$route.query.uid !== '') {
            this.configuration.UID = Number(this.$route.query.uid);
        }
    },
    beforeDestroy() {
        this.$root.$off("REQUEST_SAVE_DATA", this.saveData);
    },
};
</script>

<style>
</style>
